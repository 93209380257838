import Vue from 'vue'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import Vuelidate from 'vuelidate'
import vueScrollto from 'vue-scrollto'


import Embed from 'v-video-embed'
import VueRouter from 'vue-router'
import App from './App'
import Thank from './Thank'
Vue.use(VueRouter)

// global register
Vue.use(Embed);

const routes = {
  '/': App,
  '/merci': Thank
}

Vue.use(vueScrollto)
Vue.use(Vuelidate)
Vue.config.productionTip = false
new Vue({
  el: '#app',
  data: {
    currentRoute: window.location.pathname
  },
  computed: {
    ViewComponent () {
      return routes[this.currentRoute]
    }
  },
  render (h) { return h(this.ViewComponent) }
})